/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.videoContainer {
  position: absolute;
}
.bgVideo {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

/* Mobile */
@media only screen and (max-width: 768px) {
}
